import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, onMount, Show } from 'solid-js';
import { Elements } from 'solid-stripe';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useError } from '~/global-contexts/ErrorHandler';
import { currency } from '~/global-contexts/utils/number';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { GetRentalApplicationStatusUseCase } from '~/use-cases/rental-applications/application-steps/getRentalApplicationStatusUseCase';
import { GetRentalApplicationPaymentIntentUseCase } from '~/use-cases/rental-applications/getRentalApplicationPaymentIntentUseCase';
import { StripePayment } from '../screening/components/StripePayment';

export const PaymentStep = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { reportError } = useError();
  const [paymentSucceeded, setPaymenSucceeded] = createSignal<boolean>(false);

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplicationStatus, isLoading: isLoadingStatus } = useUseCase(GetRentalApplicationStatusUseCase);
  const { execute: getPaymentIntent, isLoading: isLoadingPaymentIntent } = useUseCase(GetRentalApplicationPaymentIntentUseCase);

  onMount(async () => {
    await getPaymentIntent({ id: params.id, password: params.password });
  });

  createEffect(() => {
    if (presentableRentalApplication()?.isPaid) {
      navigate(`/rental-applications/${params.id}/${params.password}/transunion-connection`);
    } else if (paymentSucceeded()) {
      setTimeout(() => {
        getRentalApplicationStatus({ id: params.id, password: params.password });
      }, 1000);
    }
  });

  const onPaymentSucceeded = () => {
    setPaymenSucceeded(true);
  };

  const onPaymentError = (error: Error) => {
    reportError(error);
  };

  return (
    <Show
      when={
        !isLoadingPaymentIntent() &&
        !isLoadingStatus() &&
        !paymentSucceeded() &&
        presentableRentalApplication()?.stripeInfo?.account &&
        presentableRentalApplication()?.stripeInfo?.clientSecret
      }
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <Elements
        stripe={presentableRentalApplication()?.stripeInfo?.account || null}
        clientSecret={presentableRentalApplication()?.stripeInfo?.clientSecret}>
        <StripePayment
          amount={currency(presentableRentalApplication()?.stripeInfo?.totalAmount)}
          onSuccess={onPaymentSucceeded}
          onError={onPaymentError}
        />
      </Elements>
    </Show>
  );
};
