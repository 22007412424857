import { loadStripe } from '@stripe/stripe-js';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials, StripeInfo } from '~/state/mainAppState';
import { getStripeKey } from '~/utils/stripeKey';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationUseCase } from './getRentalApplicationUseCase';
import { LockRentalApplicationUseCase } from './lockRentalApplicationUseCase';

export class GetRentalApplicationPaymentIntentUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    await new GetRentalApplicationUseCase().execute(credentials);
    const application = this.getState().user.rentalApplication.application;
    if (application?.isDraft) {
      await new LockRentalApplicationUseCase().execute();
    }
    const intents = await rentalApplicationGateway.getPaymentIntentForRentalApplication(credentials);
    const stripe: StripeInfo = {
      stripeAccountId: intents.stripeAccountId,
      clientSecret: intents.clientSecret,
      totalAmount: intents.totalAmount,
      intentId: intents.intentId,
    };
    if (!stripe.account && stripe.stripeAccountId) {
      stripe.account = (await loadStripe(getStripeKey(), { stripeAccount: stripe.stripeAccountId })) || undefined;
    }
    this.getState().user.rentalApplication.stripe = stripe;
  }
}
